import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { useAllContext } from '../context/AllContext'
import ReactPaginate from 'react-paginate'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'

const WithdrawRequest = () => {

    const adminInfo = JSON.parse(localStorage.getItem('adminInfo'))

    const { getWithdrawRequest, withdrawRequest } = useAllContext()
    

    // pagination
    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 8
    useEffect(() => {
        if (withdrawRequest) {
            // Calculate the total number of pages
            const endOffset = itemOffset + itemsPerPage;
            setCurrentItems(withdrawRequest.slice(itemOffset, endOffset));
            setPageCount(Math.ceil(withdrawRequest.length / itemsPerPage));
        }
        
    }, [itemOffset, itemsPerPage, withdrawRequest]);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % withdrawRequest.length;
        setItemOffset(newOffset);
        setCurrentPage(event.selected)
    };
    // end pagination

    const statusUpdate = async (linkId, status, transactionNum) => {
        const linkData = {
            id: `${linkId}`,
            status: `${status}`
        }
        try {
            const resp = await axios.post(`${process.env.REACT_APP_API_URL}/admin/change-withdraw-status`, linkData, {
                headers: {
                    Authorization: `${adminInfo.token}`
                }
            })
            const data = await resp.data

            if (data.status == 0) {
                toast.error(data.message)
            }

            if (data.status == 1) {
                toast.success(data.message)
                getWithdrawRequest(adminInfo.token)
            }


        } catch (error) {
            console.log('Error:', error);
        }

        
        const apiData = {
            action: "withdraw",
            TransactionNumber: transactionNum,
            status: status == 0 ? "pending" : status == 1 ? "accepted" : "rejected"
        }

        try {
            const resp = await axios.post(`https://classcontrols.com/api/transaction/deposit-and-withdraw/opg`, apiData)
            const data = await resp.data
            const dataDetails = JSON.parse(data)
            
            if (dataDetails.status === 'OK') {
                toast.success("Resend Successfully!")
            }

        } catch (error) {
            console.log('Error:', error);
        }
        
    }

    useEffect(() => {
        getWithdrawRequest(adminInfo.token)
    }, [])

    return (
        <>
            <div className='main-content'>
                <Header />

                <div className='padding'>
                    <div className='container-fluid'>
                        <div className='row gx-3'>
                            <div className='col-lg-12 col-12'>
                                <div className='card card1'>
                                    <div className="card-header">
                                        <h5 className="mb-0 fs-18 fw-500 text-white">Withdraw Request</h5>
                                    </div>
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className='table table1 mb-0' style={{ "minWidth": "650px" }}>
                                                <thead>
                                                    <tr>
                                                        <th scope="col" style={{"width": "50px"}}>#</th>
                                                        <th scope="col">Withdraw Type</th>
                                                        <th scope="col">Withdraw Method</th>
                                                        <th scope="col">Email</th>
                                                        <th scope="col">Bank Name</th>
                                                        <th scope="col">Bank Account Number</th>
                                                        <th scope="col">Bank Account Name</th>
                                                        <th scope="col">Network</th>
                                                        <th scope="col">Wallet Address</th>
                                                        <th scope="col">Currency</th>
                                                        <th scope="col">Amount</th>
                                                        <th scope="col">Final Amount</th>
                                                        <th scope="col">Fees</th>
                                                        <th scope="col">Time Period</th>
                                                        <th scope="col">Date & Time</th>
                                                        <th scope="col">Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        currentItems && currentItems.length > 0 ? currentItems.map((items, ind) => {
                                                            const date = new Date(items.createdAt).toLocaleDateString()
                                                            return <tr key={ind}>
                                                                <td>{currentPage * itemsPerPage + ind + 1}</td>
                                                                <td>{items.withdrawType}</td>
                                                                <td>{items.withdrawMethod}</td>
                                                                <td>{items.email}</td>
                                                                <td>{items.bankName}</td>
                                                                <td>{items.bankAccountNumber}</td>
                                                                <td>{items.bankAccountName}</td>
                                                                <td>{items.network}</td>
                                                                <td>{items.walletAddress}</td>
                                                                <td>{items.currency}</td>
                                                                <td>{items.amount}</td>
                                                                <td>{items.finalAmount}</td>
                                                                <td>{items.fees}</td>
                                                                <td>{items.timePeriod}</td>
                                                                <td>
                                                                    {new Date(items.createdAt).toLocaleTimeString()} <br />
                                                                    {new Date(items.createdAt).toLocaleDateString()}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        items.withdrawStatus=="0" ? <div className="dropdown dropdown1">
                                                                            <button className={`btn badge fs-14 fw-medium px-2 py-2 dropdown-toggle ${items.withdrawStatus == "0" ? 'pending': items.withdrawStatus == "1" ? 'approve': 'no' }`} type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                {items.withdrawStatus=="0" ? "Pending" : items.withdrawStatus=="1" ? 'Completed' : 'Rejected' }
                                                                            </button>
                                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                                <li><Link className="dropdown-item" onClick={() => statusUpdate(items.id, "1", items.transactionNumber)}>Completed</Link></li>
                                                                                <li><Link className="dropdown-item" onClick={() => statusUpdate(items.id, "2", items.transactionNumber)}>Rejected</Link></li>
                                                                            </ul>
                                                                        </div> :
                                                                        <span className={`badge p-2 fs-14 fw-medium ${items.withdrawStatus == "0" ? 'pending': items.withdrawStatus == "1" ? 'approve': 'no' }`}>{items.withdrawStatus=="0" ? "Pending" : items.withdrawStatus=="1" ? 'Completed' : 'Rejected' }</span>
                                                                    }
                                                                </td>
                                                                {/* <td><span className={`badge fs-14 fw-medium ${items.linkStatus == "pending" ? 'pending':'approve' }`}>{items.linkStatus}</span></td> */}
                                                                {/* <td className="text-center"><button type="submit" onClick={() => { deleteAccount(items.id) }} className="btn btn1 py-1 px-2 bg-danger border-danger text-white" style={{ "minWidth": "auto" }}><FaRegTrashAlt /></button></td> */}
                                                            </tr>
                                                        })
                                                            :
                                                            <tr>
                                                                <td colSpan={15}><p className='text-center mt-4'>No Records</p></td>
                                                            </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className='col-12'>
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel=">"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={1}
                                    pageCount={pageCount}
                                    previousLabel="<"
                                    renderOnZeroPageCount={null}
                                    containerClassName={'pagination'}
                                    pageClassName={'page-item'}
                                    pageLinkClassName={'page-link'}
                                    previousClassName={'page-item'}
                                    previousLinkClassName={'page-link'}
                                    nextClassName={'page-item'}
                                    nextLinkClassName={'page-link'}
                                    breakClassName={'page-item'}
                                    breakLinkClassName={'page-link'}
                                    activeClassName={'active'}
                                    className={'pagination pagination1 mx-auto justify-content-center mt-4 mb-0'}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </>
    )
}

export default WithdrawRequest